<template>
  <div class="m-3 sm:m-5 shadow-sm h-auto bg-white rounded">
    <div class="flex justify-between items-center border-b-0.5 p-2">
      <p class="font-medium">Users</p>
      <button
        class="bg-buttonColor rounded hover:shadow-md text-white p-1 text-sm"
        @click="openCreateUser()"
      >
        Create User
      </button>
    </div>
    <create-user
      :show-create-user="showCreateUser"
      @close="showCreateUser = false"
      @updateUsersTable="updateUsersTableHandler"
    />
    <edit-user
      :show-edit-user="showEditUser"
      :user-data="editUserData"
      @close="showEditUser = false"
      @updateUsersTable="updateUsersTableHandlerOnEdit"
    />
    <div class="pt-2">
      <transition name="fade">
        <div
          v-if="showSuccess"
          class="p-1 text-center w-full bg-green-400 text-white"
        >
          The user has been created
        </div>
      </transition>
      <transition name="fade">
        <div
          v-if="showEditSuccess"
          class="p-1 text-center w-full bg-green-400 text-white"
        >
          The user has been edited successfully
        </div>
      </transition>
      <users-table
        :has-user-list-changed="hasUserListChanged"
        @editUser="handleUserEdit"
        @updatedUsersList="hasUserListChanged = false"
      />
    </div>
  </div>
</template>

<script>
import CreateUser from "../components/users/createUser.vue";
import UsersTable from "../components/users/usersTable.vue";
import EditUser from "../components/users/editUser.vue";
export default {
  name: "Users",
  metaInfo: {
    title: "LipaLink | Users",
  },
  components: { CreateUser, UsersTable, EditUser },
  data() {
    return {
      showCreateUser: false,
      showEditUser: false,
      hasUserListChanged: false,
      showSuccess: false,
      showEditSuccess: false,
      editUserData: null,
    };
  },
  methods: {
    openCreateUser() {
      this.showCreateUser = true;
    },
    handleUserEdit(user) {
      this.showEditUser = true;
      this.editUserData = user;
    },
    updateUsersTableHandler() {
      this.showCreateUser = false;
      this.hasUserListChanged = true;
      this.showSuccess = true;
      setTimeout(() => {
        this.showSuccess = false;
      }, 3000);
    },
    updateUsersTableHandlerOnEdit() {
      this.showEditUser = false;
      this.hasUserListChanged = true;
      this.showEditSuccess = true;
      setTimeout(() => {
        this.showEditSuccess = false;
      }, 3000);
    },
  },
};
</script>