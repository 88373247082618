<template>
  <div class="relative" style="min-height: 50px">
    <div
      v-if="loading"
      class="absolute inset-0 bg-black bg-opacity-10 rounded-b z-50"
    >
      <loader class="absolute center-h-v" />
    </div>
    <div
      v-if="organizationData"
      class="grid grid-cols-1 sm:grid-cols-2 divide-x-0.5"
    >
      <div class="p-5 flex flex-col gap-5">
        <div class="flex flex-row justify-between gap-5">
          <p class="text-sm">Send Acknowledgement SMS</p>
          <label class="switch">
            <input
              @click="update('sms')"
              type="checkbox"
              v-model="organizationData.SendAcknowledgementSMS"
              :value="organizationData.SendAcknowledgementSMS"
            />
            <div class="slider round"></div>
          </label>
        </div>
        <div>
          <div class="flex flex-row justify-between gap-5 relative">
            <p class="text-sm">Sync To ERP</p>
            <label class="switch">
              <input
                @click="update('erp')"
                type="checkbox"
                v-model="organizationData.SyncToERP"
                :value="organizationData.SyncToERP"
              />
              <div class="slider round"></div>
            </label>
          </div>
          <div v-if="!SyncToERPAPiKey">
            <p class="text-sm py-5 text-textColorGray">
              In order to sync M-Pesa payment transactions to the ERP, an API
              key is required. Click the button below to generate the key. Once
              the key is generated, copy it and paste it into the ERP setup
              page.
            </p>
          </div>
          <div v-if="SyncToERPAPiKey && this.organizationData.SyncToERP">
            <p class="text-sm py-5 text-textColorGray">
              You have already generated an API key. To generate a new key,
              click the button below. The old API key will be revoked.
            </p>
            <div>
              <div class="flex gap-4 flex-wrap items-center pb-2 text-xs">
                <p class="text-textColorGray">Current API Key</p>
                <p
                  @click="copyText(organizationData.APIKey)"
                  class="cursor-pointer text-buttonColor font-bold"
                >
                  Copy key
                </p>
              </div>
              <p v-if="textCopied" class="bg-black p-2 text-white text-xs">
                The API key has been copied
              </p>
              <p
                class="text-sm bg-buttonColor text-center bg-opacity-30 text-textColorGray p-2"
              >
                {{ organizationData.APIKey }}
              </p>
            </div>
          </div>

          <button
            v-if="this.organizationData.SyncToERP"
            @click="generateApiKey"
            class="bg-buttonColor my-5 text-sm text-white rounded-md p-2"
          >
            {{
              organizationData.APIKey != null ? "Regenerate" : "Generate"
            }}
            API Key
          </button>
        </div>
      </div>
      <div class="flex flex-col gap-5 p-5">
        <p class="text-sm font-medium">Documents</p>
        <a
          class="text-buttonColor text-sm"
          :href="organizationData.CompanyRegistration"
          target="_blank"
          >Company Registration &#10064;</a
        >
        <a
          class="text-buttonColor text-sm"
          :href="organizationData.CR12"
          target="_blank"
          >CRI2 &#10064;</a
        >
        <a
          class="text-buttonColor text-sm"
          :href="organizationData.MPesaAcknowledgment"
          target="_blank"
          >Mpesa Acknowledgement &#10064;</a
        ><a
          class="text-buttonColor text-sm"
          :href="organizationData.BusinessPermit"
          target="_blank"
          >Business Permit &#10064;</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import Loader from "../loader/loader.vue";
var qs = require("qs");
export default {
  name: "OrganizationSettings",
  components: {
    Loader,
  },
  data: function () {
    return {
      loading: false,
      textCopied: false,
      SyncToERPAPiKey: false,
      organizationData: null,
    };
  },
  computed: {
    ...mapGetters(["getToken"]),
  },
  created() {
    this.getThisOrganization();
  },
  methods: {
    getThisOrganization() {
      this.loading = true;
      const options = {
        headers: {
          Authorization: `Bearer ${this.getToken}`,
        },
      };
      axios
        .get(
          `${this.$route.params.organization}/organization/request`,
          options,
          this.organizationData
        )
        .then((res) => {
          this.loading = false;
          this.organizationData = res.data;
          if (this.organizationData.APIKey) {
            this.SyncToERPAPiKey = true;
          }
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    generateApiKey() {
      this.loading = true;
      const options = {
        headers: {
          Authorization: `Bearer ${this.getToken}`,
        },
      };
      axios
        .post(
          `${this.$route.params.organization}/organization/api/key/generate`,
          qs.stringify(this.data),
          options
        )
        .then(
          (res) => {
            this.loading = false;
            let data = {
              msg: "API key has been generated",
              color: "bg-green-500",
            };
            this.$emit("status", data);
            this.getThisOrganization();
            this.organizationData.APIKey = res.data.APIKey;
            this.SyncToERPAPiKey = true;
          },
          () => {
            this.loading = false;
            let data = {
              msg: "An error occured please try again later",
              color: "bg-red-500",
            };
            this.$emit("status", data);
          }
        );
    },

    copyText(text) {
      if (this.textCopied) {
        this.textCopied = false;
      }
      navigator.clipboard.writeText(text);
      this.textCopied = true;
      setTimeout(() => {
        this.textCopied = false;
      }, 3000);
    },
    update(value) {
      this.loading = true;
      var data;
      switch (value) {
        case "erp":
          data = {
            OrganizationId: this.organizationData.Id,
            OrganizationName: this.organizationData.OrganizationName,
            OrganizationAddress: this.organizationData.OrganizationAddress,
            Contact: this.organizationData.Contact,
            Email: this.organizationData.Email,
            SendAcknowledgementSMS:
              this.organizationData.SendAcknowledgementSMS,
            SyncToERP: !this.organizationData.SyncToERP,
          };
          break;
        case "sms":
          data = {
            OrganizationId: this.organizationData.Id,
            OrganizationName: this.organizationData.OrganizationName,
            OrganizationAddress: this.organizationData.OrganizationAddress,
            Contact: this.organizationData.Contact,
            Email: this.organizationData.Email,
            SendAcknowledgementSMS:
              !this.organizationData.SendAcknowledgementSMS,
            SyncToERP: this.organizationData.SyncToERP,
          };
          break;
        default:
          break;
      }
      const options = {
        headers: {
          Authorization: `Bearer ${this.getToken}`,
        },
      };
      axios
        .put(
          `${this.$route.params.organization}/organization/update`,
          qs.stringify(data),
          options
        )
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.center-h-v {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
</style>
