<template>
  <div class="m-3 sm:m-5 h-auto">
    <!-- MY organization -->
    <div class="my-5" v-if="getUser.role == 'Company Administrator'">
      <div class="bg-white rounded-md shadow-sm">
        <div class="flex justify-between items-center border-b-0.5 px-5 pt-3 pb-2">
          <p class="font-medium">This Organization</p>
        </div>
        <p v-if="showApiKeyGenerationStatus" :class="apiKeyGenerationColor"
          class="sm:w-1/2 w-full text-center text-white p-1 text-sm">
          {{ apiKeyGenerationMsg }}
        </p>
        <organization-settings @status="handleAPiKeyGeneration"></organization-settings>
      </div>
    </div>

    <!-- SMS Gateway -->
    <div v-if="getUser.role !== 'LipaLink Administrator'">
      <div class="bg-white rounded-md shadow-sm">
        <div class="flex justify-between items-center border-b-0.5 px-5 pt-3 pb-2">
          <p class="font-medium">SMS Gateways</p>


        </div>

        <div class="flex justify-between items-center ml-0 p-2">

          <!-- search -->
          <input v-model="searchInput" type="search" placeholder="Search"
            class="border-0.5 w-52 p-0.5 rounded-0 focus:outline-none bg-white rounded-md" />
          <!-- search -->
          <button class="
              bg-buttonColor
              rounded
              hover:shadow-md
              text-white
              p-1
              text-sm
            " @click="showCreateGateway = true">
            Create Gateway
          </button>

        </div>
        <transition name="fade">
          <div v-if="showSuccess" class="p-1 text-center w-full bg-green-200">
            The SMS gateway has been created
          </div>
        </transition>
        <transition name="fade">
          <div v-if="showEditSuccess" class="p-1 text-center w-full bg-green-400 text-white">
            The gateway has been edited successfully
          </div>
        </transition>
        <transition name="fade">
          <div v-if="showDefaultSuccess" class="p-1 text-center w-full bg-green-400 text-white">
            The gateway has been made the default one
          </div>
        </transition>
        <sms-gateway-table @editGateway="handleGatewayEdit" @updateGatewayTable="updateGatewayTableHandlerOnDefault"
          :hasGatewayListChanged="hasGatewayListChanged" />
      </div>
      <create-gateway :showCreateGateway="showCreateGateway" @close="showCreateGateway = false"
        @updateGatewayTable="updateGatewayTableHandler" />
      <edit-gateway :showEditGateway="showEditGateway" :gatewayData="gatewayData" @close="showEditGateway = false"
        @updateGatewayTable="updateGatewayTableHandlerOnEdit" />
      <div class="mt-5 bg-white shadow-sm rounded-md">
        <div class="flex justify-between items-center border-b-0.5 px-5 pt-3 pb-2">
          <p class="font-medium">SMS Template</p>
        </div>
        <sms-template />
      </div>
    </div>
    <!-- Service provider -->
    <div v-if="getUser.role == 'LipaLink Administrator'">
      <div class="bg-white rounded-md shadow-sm">
        <div class="flex justify-between items-center border-b-0.5 px-5 pt-3 pb-2">
          <p class="font-medium">SMS Service Providers</p>
          <button class="
              bg-buttonColor
              rounded
              hover:shadow-md
              text-white
              p-1
              text-sm
            " @click="showcreateProvider = true">
            Create Service Provider
          </button>
        </div>
        <transition name="fade">
          <div v-if="showProviderSuccess" class="p-1 text-center w-full bg-green-200">
            The service provider has been created
          </div>
        </transition>
        <service-providers-table :hasProviderListChanged="hasProviderListChanged" />
      </div>
      <create-service-provider :showcreateProvider="showcreateProvider" @close="showcreateProvider = false"
        @updateProviderTable="updateProviderTableHandler" />
    </div>
  </div>
</template>

<script>
import SmsGatewayTable from "../components/setups/smsGatewaysTable.vue";
import SmsTemplate from "../components/setups/smsTemplate.vue";
import CreateGateway from "../components/setups/createGateway.vue";
import serviceProvidersTable from "../components/setups/serviceProvidersTable.vue";
import createServiceProvider from "../components/setups/createServiceProvider.vue";
import ServiceProvidersTable from "../components/setups/serviceProvidersTable.vue";
import CreateServiceProvider from "../components/setups/createServiceProvider.vue";
import EditGateway from "../components/setups/editGateway.vue";
import organizationSettings from "../components/setups/organizationSettings.vue";
import { mapGetters } from "vuex";
import OrganizationSettings from "../components/setups/organizationSettings.vue";
export default {
  name: "Setups",
  components: {
    SmsGatewayTable,
    SmsTemplate,
    CreateGateway,
    serviceProvidersTable,
    createServiceProvider,
    ServiceProvidersTable,
    CreateServiceProvider,
    EditGateway,
    organizationSettings,
    OrganizationSettings,
  },
  metaInfo: {
    title: "LipaLink | Setups",
  },
  computed: {
    ...mapGetters(["getUser"]),
  },
  data() {
    return {
      showCreateGateway: false,
      showApiKeyGenerationStatus: false,
      apiKeyGenerationMsg: "",
      apiKeyGenerationColor: "",
      showEditGateway: false,
      gatewayData: null,
      showDefaultSuccess: false,
      showcreateProvider: false,
      hasGatewayListChanged: false,
      hasProviderListChanged: false,
      showSuccess: false,
      showEditSuccess: false,
      showProviderSuccess: false,
    };
  },

  methods: {
    handleAPiKeyGeneration(value) {
      this.showApiKeyGenerationStatus = true;
      setTimeout(() => {
        this.showApiKeyGenerationStatus = false;
      }, 3000);
      this.apiKeyGenerationMsg = value.msg;
      this.apiKeyGenerationColor = value.color;
    },
    handleGatewayEdit(gateway) {
      this.showEditGateway = true;
      this.gatewayData = gateway;
    },
    updateGatewayTableHandler() {
      this.showCreateGateway = false;
      this.hasGatewayListChanged = true;
      this.showSuccess = true;
      setTimeout(() => {
        this.showSuccess = false;
      }, 3000);
    },
    updateGatewayTableHandlerOnDefault() {
      this.showDefaultSuccess = true;
      setTimeout(() => {
        this.showDefaultSuccess = false;
      }, 3000);
    },
    updateGatewayTableHandlerOnEdit() {
      this.showEditGateway = false;
      this.hasGatewayListChanged = true;
      this.showEditSuccess = true;
      setTimeout(() => {
        this.showEditSuccess = false;
      }, 3000);
    },
    updateProviderTableHandler() {
      this.showcreateProvider = false;
      this.hasProviderListChanged = true;
      this.showProviderSuccess = true;
      setTimeout(() => {
        this.showProviderSuccess = false;
      }, 3000);
    },
  },
};
</script>