<template>
  <div class="pt-2 relative">
    <div v-if="loading" class="absolute inset-0 bg-black bg-opacity-10 z-50">
      <loader class="absolute center-h-v" />
    </div>
    <div class="flex gap-2">
      <ve-pagination v-if="!loading" :page-size="pageInformation.pageSize" :total="pageInformation.totalCount"
        :layout="['sizer']" :pageSizeOption="[1, 5, 10, 20]" @on-page-size-change="pageSizeChange" />
      <form v-if="!loading" @submit.prevent="search" class="
          border-0.5
          flex
          items-center
          justify-between
          text-xs
          border-inputBorder
          p-1
          mr-2
          rounded-sm
        ">
        <input v-model="searchTag" class="focus:outline-none" placeholder="search" />
        <button type="submit">
          <img class="h-4 object-contain cursor-pointer" src="@/assets/icons/search.png" />
        </button>
      </form>
    </div>



    <ve-table class="p-4 mt-2" :row-style-option="rowStyleOption" :columns="columns" :table-data="tableData"
      :border-x="true" :border-y="true" :sort-option="sortOption" />
    <div v-if="tableData.length == 0 && !loading" class="p-2 text-center">
      No transactions yet
    </div>

    <div class="p-2 text-center">
      <ve-pagination v-if="!loading" :total="pageInformation.totalCount" :page-index="pageInformation.currentPage"
        :page-size="pageInformation.pageSize" :layout="['prev', 'pager', 'next']"
        @on-page-number-change="pageNumberChange" />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import loader from "../loader/loader.vue";
export default {
  name: "TransactionsTable",
  components: { loader },

  data() {
    return {
      rowStyleOption: {
        stripe: true,
      },
      cellStyleOption: {
        bodyCellClass: () => {
          return "table-body-cell-class";
        },
      },
      searchTag: "",
      currentRowData: null,
      pageIndex: 1,
      pageSize: 10,
      sortOption: {
        sortChange: (params) => {
          this.sortData(params);
        },
      },
      columns: [
        {
          field: "",
          key: "numbering",
          title: "#",
          width: 50,
          align: "center",
          renderBodyCell: ({ rowIndex }) => {
            return (this.pageIndex - 1) * this.pageSize + rowIndex + 1;
          },
        },
        {
          field: "PaymentCode",
          key: "a",
          title: "Payment Code",
          align: "left",
          sortBy: "",
        },
        {
          field: "Account",
          key: "b",
          title: "Account",
          align: "left",
          sortBy: "",
        },
        {
          field: "PaidBy",
          key: "c",
          title: "Paid By",
          align: "left",
          sortBy: "",
        },
        {
          field: "Shortcode",
          key: "d",
          title: "ShortCode",
          align: "left",
          sortBy: "",
        },
        {
          field: "Mobile",
          key: "e",
          title: "Mobile",
          align: "left",
          sortBy: "",
        },
        {
          field: "Amount",
          key: "f",
          title: "Amount",
          align: "left",
          renderBodyCell: ({ row, column }) => {
            const value = row[column.field];
            return `KES ${Number(value).toLocaleString()}`;
          },
          sortBy: "",
        },
        {
          field: "PaymentTime",
          key: "g",
          title: "Payment Time",
          align: "left",
          renderBodyCell: ({ row, column }) => {
            const value = row[column.field];
            return this.formatTime(value);
          },
          sortBy: "",
        },
        {
          field: "SyncToERP",
          key: "iii",
          title: "Sync To ERP",
          align: "left",
        },
        {
          field: "DateSynced",
          key: "h",
          title: "Date Synced",
          align: "left",
          renderBodyCell: ({ row, column }) => {
            const value = row[column.field];
            return this.formatTime(value);
          },
          sortBy: "",
        },

      ],
      tableData: [],
      loading: false,
      pageInformation: null,
      sortObj: {
        column: "paymenttime",
        order: "desc",
      },
    };
  },

  computed: {
    ...mapGetters(["getToken"]),
  },
  created() {
    this.getTransactions(
      this.searchTag,
      this.pageIndex,
      this.pageSize,
      this.sortObj
    );
  },

  methods: {
    sortData(params) {
      const keys = Object.keys(params);
      let sortObj;
      const isEmpty = Object.values(params).every(
        (x) => x === null || x === ""
      );
      if (!isEmpty) {
        keys.forEach((key) => {
          if (params[key] !== "") {
            sortObj = {
              column: key.toLowerCase(),
              order: params[key],
            };
          }
        });
      } else {
        sortObj = {
          column: "paymenttime",
          order: "desc",
        };
      }

      this.getTransactions(
        this.searchTag,
        this.pageIndex,
        this.pageSize,
        sortObj
      );
    },
    formatTime(value) {
      if (value) {
        var date = value.split("T")[0];
        var time = value.split("T")[1].substring(0, 5);
        var hour = parseInt(time.split(":")[0]);
        var minute = time.split(":")[1];
        var period = hour >= 12 ? "PM" : "AM";

        // Convert hour to 12-hour format
        if (hour > 12) {
          hour -= 12;
        } else if (hour === 0) {
          hour = 12;
        }

        time = hour.toString().padStart(2, "0") + ":" + minute;
        return `${date} ${time} ${period}`;
      }
    },

    search() {
      this.getTransactions(
        this.searchTag,
        this.pageIndex,
        this.pageSize,
        this.sortObj
      );
    },
    pageSizeChange(size) {
      this.pageIndex = 1;
      this.getTransactions(
        this.searchTag,
        this.pageInformation.pageNumber,
        size,
        this.sortObj
      );
      this.pageSize = size;
    },
    pageNumberChange(number) {
      this.getTransactions(
        this.searchTag,
        number,
        this.pageInformation.pageSize,
        this.sortObj
      );
      this.pageIndex = number;
    },
    getTransactions(searchTag, pageNumber, pageSize, sortObj) {
      this.loading = true;
      this.tableData = [];
      const options = {
        headers: {
          Authorization: `Bearer ${this.getToken}`,
        },
      };
      axios
        .get(
          `${this.$route.params.organization}/transaction/query?sortString=${sortObj.column}&order=${sortObj.order}&searchString=${searchTag}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
          options
        )
        .then(
          (response) => {
            response.data.Transactions.forEach((Transaction) => {
              this.tableData.push(Transaction);
            });
            this.pageInformation = response.data.PageInformation;
            this.loading = false;
          },
          () => {
            this.loading = false;
          }
        );
    },
  },
};
</script>

<style scoped></style>