<template>
  <div class="m-3 sm:m-5 shadow-sm h-auto bg-white rounded-md">
    <div class="flex justify-between items-center border-b-0.5 p-2">
      <p class="font-medium">Shortcodes</p>
      <button v-if="getUser.role !== 'LipaLink Administrator' && getUser.role !== 'Paybill User'"
        class="bg-buttonColor rounded hover:shadow-md text-white p-1 text-sm" @click="openCreateShortcode()">
        Create Shortcode
      </button>
    </div>
    <create-shortcode :show-create-shortcode="showCreateShortcode" @close="showCreateShortcode = false"
      @updateShortcodesTable="updateShortcodesTableHandler" />
    <div>
      <transition name="fade">
        <div v-if="showSuccess" class="p-1 text-center w-full bg-green-200">
          The shortcode has been created
        </div>
      </transition>
      <transition name="fade">
        <div v-if="showcallbackRegistrationSuccess" class="p-1 text-center w-full bg-green-200">
          Callback URL has been registered
        </div>
      </transition>
      <transition name="fade">
        <div v-if="showcallbackRegistrationError" class="p-1 text-center w-full bg-yellow-200">
          {{ callbackError.message }}
        </div>
      </transition>
      <shortcodes-table :has-shortcodes-list-changed="hasShortcodesListChanged"
        @callbackRegistration="handleCallbackRegistration" />
    </div>
  </div>
</template>

<script>
import CreateShortcode from "../components/shortcodes/createShortcode.vue";
import ShortcodesTable from "../components/shortcodes/shortcodesTable.vue";
import { mapGetters } from "vuex";
export default {
  name: "Shortcodes",
  metaInfo: {
    title: "LipaLink | Shortcodes",
  },
  components: { CreateShortcode, ShortcodesTable },
  data() {
    return {
      showCreateShortcode: false,
      showSuccess: false,
      showcallbackRegistrationError: false,
      showcallbackRegistrationSuccess: false,
      hasShortcodesListChanged: false,
      callbackError: {},
    };
  },
  computed: {
    ...mapGetters(["getUser"]),
  },
  methods: {
    openCreateShortcode() {
      this.showCreateShortcode = true;
    },
    updateShortcodesTableHandler() {
      this.showCreateShortcode = false;
      this.hasShortcodesListChanged = true;
      this.showSuccess = true;
      setTimeout(() => {
        this.showSuccess = false;
      }, 3000);
    },
    handleCallbackRegistration(value, error = {}) {
      this.callbackError = error
      if (value) {
        this.showcallbackRegistrationSuccess = true;
        setTimeout(() => {
          this.showcallbackRegistrationSuccess = false;
        }, 3500);
      } else {
        this.showcallbackRegistrationError = true;
        setTimeout(() => {
          this.showcallbackRegistrationError = false;
        }, 3500);
      }
    },
  },
};
</script>