<template>
  <div class="m-3 sm:m-5 shadow-sm h-auto bg-white rounded-md">
    <div class="flex justify-between items-center border-b-0.5 p-2">
      <p class="font-medium">
        Transactions
      </p>
    </div>
    <div>
      <transactions-table />
    </div>
  </div>
</template>
<script>
import TransactionsTable from "../components/transactions/transactionsTable.vue";
export default {
  name: "Transactions",
  components: { TransactionsTable },
  metaInfo: {
    title: "LipaLink | Transactions",
  },
};
</script>