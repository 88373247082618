<template>
  <div class="pt-2 relative">
    <div v-if="loading" class="absolute inset-0 bg-black bg-opacity-10 z-40">
      <loader class="absolute center-h-v" />
    </div>
    <div class="flex justify-between">
      <ve-pagination v-if="!loading" :page-size="pageInformation.pageSize" :total="pageInformation.totalCount"
        :layout="['sizer']" :pageSizeOption="[5, 10, 20]" @on-page-size-change="pageSizeChange" />
      <form v-if="!loading" @submit.prevent="search" class="
          border-0.5
          flex
          items-center
          justify-between
          text-xs
          border-inputBorder
          p-1
          mr-2
          rounded-sm
        ">
        <input v-model="searchTag" class="focus:outline-none" placeholder="search" />
        <button type="submit">
          <img class="h-4 object-contain cursor-pointer" src="@/assets/icons/search.png" />
        </button>
      </form>
    </div>
    <ve-table class="p-2 text-xs mt-2" :row-style-option="rowStyleOption" ref="tableRef" :columns="columns"
      :table-data="tableData" :border-x="true" :border-y="true" :sort-option="sortOption"
      :columnHiddenOption="columnHiddenOption" />

    <div class="p-2">
      <ve-pagination v-if="!loading" :total="pageInformation.totalCount" :page-index="pageInformation.currentPage"
        :page-size="pageInformation.pageSize" :layout="['prev', 'pager', 'next']"
        @on-page-number-change="pageNumberChange" />
    </div>
    <assigned-shortcodes :showAssignedShortcodes="showAssignedShortcodes" @close="close"
      :currentRowData="currentRowData"></assigned-shortcodes>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
var qs = require("qs");
import loader from "../loader/loader.vue";
import AssignedShortcodes from "./assignedShortcodes.vue";
export default {
  name: "UsersTable",
  components: { loader, AssignedShortcodes },
  props: ["hasUserListChanged"],
  data() {
    return {
      rowStyleOption: {
        stripe: true,
      },
      showAssignedShortcodes: false,
      currentRowData: null,
      pageIndex: 1,
      pageSize: 10,
      searchTag: null,
      sortOption: {
        sortChange: (params) => {
          this.sortData(params);
        },
      },
      columnHiddenOption: {
        // default hidden column keys

        defaultHiddenColumnKeys: [""],
      },
      columns: [
        {
          field: "",
          key: "numbering",
          title: "#",
          width: 50,
          align: "center",
          renderBodyCell: ({ rowIndex }) => {
            return (this.pageIndex - 1) * this.pageSize + rowIndex + 1;
          },
        },

        {
          field: "FullName",
          key: "a",
          title: "Name",
          align: "left",
          sortBy: "",
          renderBodyCell: ({ row }) => {
            return (
              <p
                on-click={() => this.editRow(row)}
                class="hover:underline text-blue-500 cursor-pointer"
              >
                {row.FullName}
              </p>
            );
          },
        },
        // {
        //   field: "",
        //   key: "cx",
        //   title: "Edit",
        //   align: "center",
        //   renderBodyCell: ({ row }) => {
        //     return (
        //       <p
        //         on-click={() => this.editRow(row)}
        //         class=" text-blue-500 cursor-pointer font-bold"
        //       >
        //         <span>&#9998;</span>
        //       </p>
        //     );
        //   },
        // },
        { field: "Email", key: "b", title: "Email", align: "left", sortBy: "" },
        { field: "Role", key: "c", title: "Role", align: "left", sortBy: "" },
        {
          field: "Organization",
          key: "d",
          title: "Organizations",
          align: "left",
          sortBy: "",
        },
        {
          field: "",
          key: "e",
          title: "Shortcodes",
          align: "left",
          renderBodyCell: ({ row }) => {
            return (
              <p
                on-click={() => this.open(row)}
                class="hover:underline text-blue-500 cursor-pointer"
              >
                Assign
              </p>
            );
          },
        },
        {
          field: "",
          key: "f",
          title: "Enable / Disable User",
          align: "center",
          renderBodyCell: ({ row }) => {
            if (row.UserName != this.getUser.userName) {
              return (
                <div>
                  <label class="switch">
                    {row.Disabled ? (
                      <input
                        on-click={() => this.disableUser(row, false)}
                        type="checkbox"
                      />
                    ) : (
                      <input
                        on-click={() => this.disableUser(row, true)}
                        type="checkbox"
                        checked
                      />
                    )}
                    <div class="slider round"></div>
                  </label>
                </div>
              );
            }
          },
        },
        {
          field: "",
          key: "g",
          title: "Delete User",
          align: "center",
          renderBodyCell: ({ row }) => {
            if (row.UserName != this.getUser.userName) {
              return (
                <div>
                  <button
                    on-click={() => this.deleteUser(row)}
                    class="bg-buttonColor text-white p-1 rounded-sm font-medium text-sm"
                  >
                    Delete
                  </button>
                </div>
              );
            }
          },
        },
      ],
      tableData: [],
      loading: false,
      pageInformation: null,
      sortObj: {
        column: "name",
        order: "asc",
      },
    };
  },
  watch: {
    hasUserListChanged: function (val) {
      if (val) {
        this.getUsers(
          this.searchTag,
          this.pageIndex,
          this.pageSize,
          this.sortObj
        );
      }
    },
  },
  computed: {
    ...mapGetters(["getToken", "getUser"]),
  },
  created() {
    this.getUsers(this.searchTag, this.pageIndex, this.pageSize, this.sortObj);
    if (this.getUser.role == "LipaLink Administrator") {
      this.columnHiddenOption.defaultHiddenColumnKeys = ["e", "g", "f"];
    }
  },
  methods: {
    sortData(params) {
      const keys = Object.keys(params);
      let sortObj;
      const isEmpty = Object.values(params).every(
        (x) => x === null || x === ""
      );
      if (!isEmpty) {
        keys.forEach((key) => {
          if (params[key] !== "") {
            sortObj = {
              column: key.toLowerCase(),
              order: params[key],
            };
          }
        });
      } else {
        sortObj = {
          column: "name",
          order: "asc",
        };
      }

      this.getUsers(this.searchTag, this.pageIndex, this.pageSize, sortObj);
    },
    close() {
      this.showAssignedShortcodes = false;
    },
    disableUser(row, value) {
      this.loading = true;
      const options = {
        headers: {
          Authorization: `Bearer ${this.getToken}`,
        },
      };
      const data = {
        UserId: row.Id,
        Disable: value,
      };
      axios
        .put(
          `${this.$route.params.organization}/user/disable`,
          qs.stringify(data),
          options
        )
        .then(
          () => {
            this.loading = false;
            this.$emit("updateUsersTable");
          },
          () => {
            this.loading = false;
          }
        );
    },
    deleteUser(row) {
      this.loading = true;
      const options = {
        headers: {
          Authorization: `Bearer ${this.getToken}`,
        },
      };
      // const data = {
      //   UserId: row.Id,
      // };
      axios
        .delete(
          `${this.$route.params.organization}/user/${row.Id}/delete`,
          options
        )
        .then(
          () => {
            this.loading = false;
            this.$emit("updateUsersTable");
          },
          () => {
            this.loading = false;
          }
        );
    },
    search() {
      this.getUsers(
        this.searchTag,
        this.pageIndex,
        this.pageSize,
        this.sortObj
      );
    },
    pageSizeChange(size) {
      this.pageIndex = 1;
      this.getUsers(
        this.searchTag,
        this.pageInformation.pageNumber,
        size,
        this.sortObj
      );
      this.pageSize = size;
    },
    pageNumberChange(number) {
      this.getUsers(
        this.searchTag,
        number,
        this.pageInformation.pageSize,
        this.sortObj
      );
      this.pageIndex = number;
    },
    open(row) {
      this.showAssignedShortcodes = true;
      this.currentRowData = row;
    },
    editRow(row) {
      this.$emit("editUser", row);
    },
    getUsers() {
      this.loading = true;
      this.tableData = [];

      const options = {
        headers: {
          Authorization: `Bearer ${this.getToken}`,
        },
      };
      axios
        .get(
          `${this.$route.params.organization}/user/query`,
          options
        )
        .then(
          (response) => {
            response.data.forEach((user) => {
              this.tableData.push(user);
            });

            this.pageInformation = response.data;
            this.loading = false;
            this.$emit("updatedUsersList", true);
          },
          (error) => {
            console.log(error)
            this.loading = false;
            //this.error = true;
          }
        ).catch(error => {
          console.log(error)
        });
    },
  },
};
</script>

